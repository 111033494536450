import logo from "./wi.png";
import "./App.css";

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Dialog, Transition } from "@headlessui/react";

import Header from "./components/Header";

import Menu from "./components/Menu";
import Address from "./components/Address";

import WiMap from "./components/Map/WiMap";
import LocButton from "./components/Map/LocButton";
import Search from "./components/Search";

import DialogBox from "./components/DialogBox";
import ShareBox from "./components/ShareBox";
import WiAddress from "./context/WiAddress";
import WiPopup from "./components/Map/WiPopup";

import PalestineFlag from "./pray_for_palestine.png";
import LangDialogBox from "./components/LangDialogBox";

import { ResizeObserver as Polyfill } from "@juggle/resize-observer";

if ("ResizeObserver" in window === false) {
  // set the ResizeObserver polyfill.
  window.ResizeObserver = Polyfill;
}

function App() {
  const [showMenu, setShowMenu] = useState(false);
  const [showPending, setShowPending] = useState(false);

  const [showDialog, setShowDialog] = useState(false);
  const [showLangDialog, setShowLangDialog] = useState(false);

  const [showShareDialog, setShowShareDialog] = useState(false);

  const [titleDialog, setTitleDialog] = useState(-1);

  const [showPoiVal, setShowPoiVal] = useState(false);

  const ctx = useContext(WiAddress);

  let options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  const closeDialog = useCallback(() => {
    //console.log("close Dialog");
    setShowDialog(false);

    //if close function is called during localization DialogBox launch localization
    if (titleDialog == -1) {
      getLocationHandler();
    }
  });

  const closeLangDialog = useCallback(() => {
    setShowLangDialog(false);

    let sharedAddress = decodeURIComponent(window.location.search)
      .replace("?address=", "")
      .replace(",", "/")
      .split("/");

    if (sharedAddress.length <= 1) {
      setShowDialog(true);
    }
  });

  const openDialog = useCallback((title, poiVal) => {
    setTitleDialog(title);
    setShowPoiVal(poiVal);
    //console.log("open Dialog");
    setShowDialog(true);
  });

  const closeShareDialog = useCallback(() => {
    //console.log("close Share Dialog");
    setShowShareDialog(false);
  });

  const openShareDialog = useCallback((poiVal) => {
    //console.log("open Share Dialog");
    setShowPoiVal(poiVal);
    setShowShareDialog(true);
  });

  // localization function handlers *************************

  const getLocationHandler = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      handleLocationSucess,
      handleError,
      options
    );
    //setButtonClicked(true);
    //activate pending modal
    setShowPending(true);
  }, [setShowPending]);

  const handleLocationSucess = useCallback(
    (position) => {
      const { latitude, longitude } = position.coords;
      //console.log("position: " + latitude + " , " + longitude);

      //update context data
      // ctx.setLatitude(latitude);
      // ctx.setLongitute(longitude);
      // ctx.setLongLat({ lat: latitude, long: longitude });
      // ctx.setZoom(18);
      // //console.log("setZoom 18");

      //http request to get Wi-Address
      fetch(ctx.host + "/reverseGeocode/" + latitude + "," + longitude)
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          }

          // Examine the text in the response
          response.json().then((data) => {
            //console.log(data);
            //console.log("Commune: " + data.properties.name);
            //console.log("Code: " + data.properties.code);
            //console.log("Postal code: " + data.properties.wilaya);
            //console.log("Pays: " + data.properties.pays);
            let code_array = data.properties.code;
            // let code =
            //   code_array[0] +
            //   code_array[1] +
            //   code_array[2] +
            //   code_array[3] +
            //   code_array[4];

            let code = "";
            for (let i = 0; i < code_array.length; i++) {
              code += code_array[i];
            }
            //console.log("Code: " + code);
            ctx.setCommune(data.properties.name);
            ctx.setCommune_ar(data.properties.name_ar);
            ctx.setCode(code);
            ctx.setPostal_code(data.properties.wilaya);
            ctx.setPays(data.properties.pays);

            ctx.setLongLat({
              long: data.geometry.coordinates[0],
              lat: data.geometry.coordinates[1],
            });

            ctx.setZoom(18);
          });
        })
        .catch(function (err) {
          //console.log("Fetch Error :-S", err);
        });

      // deactivate pending modal
      setShowPending(false);
    },
    [setShowPending]
  );

  const handleError = useCallback(
    (error) => {
      //console.log("error: " + error.code);
      switch (error.code) {
        case 0:
          // Unknow error.
          //console.log("Unknown error");
          break;
        case 1:
          // User denied the request.
          //console.log("User denied the request");
          break;
        case 2:
          // Position not available.
          //console.log("Position not available");
          break;
        case 3:
          // Handle timeout.
          //console.log("Timeout");
          break;
      }

      // deactivate pending modal
      setShowPending(false);
    },
    [setShowPending]
  );

  //******************************** */

  useEffect(async () => {
    // if language is not set, show popup for selecting language, otherwise set language
    let language = window.localStorage.getItem("Lang");
    if (!language) {
      setShowLangDialog(true);
    } else {
      ctx.setLanguage(language);
    }

    //console.log("link: " + window.location.search.replace("?address=", ""));
    let sharedAddress = decodeURIComponent(window.location.search)
      .replace("?address=", "")
      .replace(",", "/")
      .split("/");

    //console.log(sharedAddress.length);
    if (sharedAddress.length > 1) {
      //console.log("here" + sharedAddress[0]);

      let commune = sharedAddress[0];
      let postalCode = sharedAddress[1];
      let code = sharedAddress.length === 3 ? sharedAddress[2] : "";

      let response = await fetch(
        ctx.host +
          "/geocoder?address=" +
          commune +
          "," +
          postalCode +
          "," +
          code
      );
      if (response.status !== 200) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      let data = await response.json();

      //console.log(data);

      let code_array = data.properties.code;

      let code2 = "";
      for (let i = 0; i < code_array.length; i++) {
        code2 += code_array[i];
      }
      // let code2 = code_array[0] + code_array[1] + code_array[2] + code_array[3];

      ctx.setCommune(data.properties.name);
      ctx.setCommune_ar(data.properties.name_ar);
      ctx.setCode(code2);
      ctx.setPostal_code(data.properties.wilaya);
      ctx.setPays(data.properties.pays);
      // ctx.setLatitude(data.geometry.coordinates[1]);
      // ctx.setLongitute(data.geometry.coordinates[0]);
      ctx.setLongLat({
        long: data.geometry.coordinates[0],
        lat: data.geometry.coordinates[1],
      });

      ctx.setZoom(16);

      //show tutorial info if first or second visit
      // let val = window.localStorage.getItem("NbVisit");
      //console.log("val2 = " + val);
      // if (!val || val == 1) {
      //   setTimeout(() => {
      //     openDialog(4, false);
      //   }, 10000);
      // }
    }
    // if not address present in the address bar show localization dialog box
    else {
      let val = window.localStorage.getItem("NbVisit");
      //console.log("val = " + val);
      if (!val) {
        //setShowDialog(true);
        window.localStorage.setItem("NbVisit", 1);
      } else if (val == 1) {
        setShowDialog(true);
        window.localStorage.setItem("NbVisit", 2);
      } else {
        window.localStorage.setItem("NbVisit", 3);
        setShowDialog(false);
        getLocationHandler();
      }
    }
  }, []);

  //console.log("Draw App");

  let pending_css =
    "bg-green-700 border-gray-200 border-2 mx-1 p-2 rounded-full shadow-lg shadow-green-700/50";

  return (
    <div className="App">
      <div className="RootGrid grid grid-cols-[250px,1fr] grid-rows-[64px,1fr,1fr,1fr,120px]  w-screen  items-stretch absolute inset-0">
        <div className="col-span-2">
          <Header setShowMenu={setShowMenu}></Header>
        </div>
        <div className="col-start-1 col-span-2 row-start-2 row-span-4  bg-gray-500 z-10 ">
          <WiMap></WiMap>
        </div>
        <Transition
          show={showMenu}
          enter="transition-transform duration-200 ease-linear"
          enterFrom="-translate-x-40"
          enterTo="translate-x-0"
          leave="transition-opacity transition-transform duration-200 ease-linear"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-40"
          className="rounded-tr-lg rounded-br-lg row-start-2 row-span-4 col-start-1 bg-black z-40 "
        >
          {/* Will fade in and out */}
          <Menu openDialog={openDialog}></Menu>
        </Transition>
        {showPending && (
          <div className="row-start-2 row-span-4 col-start-1 col-span-2 flex justify-center items-center bg-gray-300 bg-opacity-50 z-40">
            <div className="flex align-center items-center  w-20 h-5 ">
              <span
                className={` ${pending_css} animate-[bounce_1s_ease-in-out_infinite_0ms]`}
              ></span>
              <span
                className={` ${pending_css} animate-[bounce_1s_ease-in-out_infinite_200ms]`}
              ></span>
              <span
                className={` ${pending_css} animate-[bounce_1s_ease-in-out_infinite_400ms]`}
              ></span>
            </div>
          </div>
        )}
        {/************************** Language Dialog box  ***************/}
        {showLangDialog && (
          <LangDialogBox
            closeDialog={closeLangDialog}
            showDialog={showLangDialog}
          ></LangDialogBox>
        )}

        {/**************************  Dialog box  ***************/}
        <DialogBox
          closeDialog={closeDialog}
          showDialog={showDialog}
          titleDialog={titleDialog}
          showPoiVal={showPoiVal}
        ></DialogBox>
        {/*************************Share Box************************/}
        <ShareBox
          closeDialog={closeShareDialog}
          showDialog={showShareDialog}
          showPoiVal={showPoiVal}
        ></ShareBox>
        {/*************************************************/}
        <WiPopup
          openDialog={openDialog}
          openShareDialog={openShareDialog}
        ></WiPopup>
        {/* <div className="border-2 row-start-2 row-span-4 col-start-1 bg-red-500 hidden sm:grid z-20">
          <span>SideBar</span>
          <Address breakpoint="true"></Address>
        </div> */}
        <div className=" col-start-1 col-span-2 row-start-5  z-10 opacity-100">
          <Address
            /*breakpoint="false"*/ openDialog={openDialog}
            openShareDialog={openShareDialog}
          ></Address>
        </div>
        <div className="absolute inset-x-0 top-[65px]   mx-auto  flex  justify-center max-w-sm ">
          <img className="h-12 my-auto z-10" src={PalestineFlag} />
        </div>
        <div className="absolute inset-x-0 top-[120px]   mx-auto z-10 flex flex-col max-w-sm ">
          <Search
            /*breakpoint="false"*/ openDialog={openDialog}
            openShareDialog={openShareDialog}
          ></Search>
        </div>
        {/* <div className="mx-auto  max-w-sm hidden sm:flex sm:flex-col">
            <Search></Search>
          </div> */}
        <div className="absolute inset-y-0 right-0 my-auto h-16 flex flex-col justify-center items-end   z-20 ">
          <LocButton setShowPending={setShowPending}></LocButton>
        </div>
      </div>
    </div>
  );
}

export default App;
