import React, { useContext } from "react";

import WiAddress from "../../context/WiAddress";

const Contact = (props) => {
  const ctx = useContext(WiAddress);

  const list_names = [
    {
      contactUs_text1:
        "Nous mettons tout en œuvre pour vous répondre dans les meilleurs délais.\nToute l'équipe WiMapper vous remercie de votre visite.\n",
      contactUs_text2: "Contactez nous !",
      css_orientation: " text-left ",
    },
    {
      contactUs_text1:
        ".سنعمل بكل جدية للرد عليكم في أسرع وقت \nفريق وي مابر يشكركم على زيارتكم",
      contactUs_text2: "إتصلوا بنا",
      css_orientation: " text-right ",
    },
    {
      contactUs_text1:
        "We are making every effort to respond to you as quickly as possible. \nThe entire WiMapper team thanks you for your visit.\n",
      contactUs_text2: "Contact Us !",
      css_orientation: " text-left ",
    },
  ];

  return (
    <>
      <div className="mt-2 border-t-2 pt-2">
        <p
          className={
            "text-sm text-gray-700 leading-loose whitespace-pre-line" +
            list_names[ctx.language].css_orientation
          }
        >
          {list_names[ctx.language].contactUs_text1}
        </p>
        <div className="flex justify-center mt-6">
          <a href="mailto:contact@wimapper.com">
            <button
              type="button"
              className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
              // onClick={closeDialog}
            >
              {list_names[ctx.language].contactUs_text2}
            </button>
          </a>
        </div>
      </div>
    </>
  );
};

export default Contact;
