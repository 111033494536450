import React, { useCallback, useState, useContext } from "react";
import WiAddress from "../../context/WiAddress";

let options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

const LocButton = (props) => {
  const [button_clicked, setButtonClicked] = useState(false);

  const ctx = useContext(WiAddress);

  const getLocationHandler = useCallback(() => {
    navigator.geolocation.getCurrentPosition(
      handleLocationSucess,
      handleError,
      options
    );
    setButtonClicked(true);
    //activate pending modal
    props.setShowPending(true);
  }, [props.setShowPending]);

  const handleLocationSucess = useCallback(
    (position) => {
      const { latitude, longitude } = position.coords;
      //console.log("position: " + latitude + " , " + longitude);

      //update context data
      // ctx.setLatitude(latitude);
      // ctx.setLongitute(longitude);
      // ctx.setLongLat({ lat: latitude, long: longitude });
      // ctx.setZoom(18);
      // //console.log("setZoom 18");

      //http request to get Wi-Address
      fetch(ctx.host + "/reverseGeocode/" + latitude + "," + longitude)
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          }

          // Examine the text in the response
          response.json().then((data) => {
            //console.log(data);
            //console.log("Commune: " + data.properties.name);
            //console.log("Code: " + data.properties.code);
            //console.log("Postal code: " + data.properties.wilaya);
            let code_array = data.properties.code;
            // let code =
            //   code_array[0] +
            //   code_array[1] +
            //   code_array[2] +
            //   code_array[3] +
            //   code_array[4];

            let code = "";
            for (let i = 0; i < code_array.length; i++) {
              code += code_array[i];
            }

            //console.log("Code: " + code);
            ctx.setCommune(data.properties.name);
            ctx.setCommune_ar(data.properties.name_ar);
            ctx.setCode(code);
            ctx.setPostal_code(data.properties.wilaya);
            ctx.setPays(data.properties.pays);

            ctx.setLongLat({
              long: data.geometry.coordinates[0],
              lat: data.geometry.coordinates[1],
            });

            ctx.setZoom(18);
          });
        })
        .catch(function (err) {
          //console.log("Fetch Error :-S", err);
        });

      // deactivate pending modal
      props.setShowPending(false);
    },
    [props.setShowPending]
  );

  const handleError = useCallback(
    (error) => {
      //console.log("error: " + error.code);
      switch (error.code) {
        case 1:
          // User denied the request.
          //console.log("User denied the request");
          break;
        case 2:
          // Position not available.
          //console.log("Position not available");
          break;
        case 3:
          // Handle timeout.
          //console.log("Timeout");
          break;
      }

      // deactivate pending modal
      props.setShowPending(false);
    },
    [props.setShowPending]
  );

  let animate = button_clicked
    ? "motion-reduce:animate-ping"
    : "motion-safe:animate-ping";

  return (
    <div
      className="h-10 w-10 bg-white  hover:border-gray-500 hover:cursor-pointer focus:border-gray-500    rounded-full flex justify-center items-center m-1 border-2 border-green-700/80 shadow-xl shadow-black/50"
      onClick={getLocationHandler}
    >
      {/* <span className="flex h-4 w-4">
        <span
          className={`${animate} absolute inline-flex h-4 w-4 rounded-full bg-green-500 opacity-75`}
        ></span>
        <span className="relative inline-flex rounded-full m-auto  h-3 w-3 bg-green-600"></span>
      </span> */}

      <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24px"
        viewBox="0 0 24 24"
        width="24px"
        fill="#000000"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M18.75 3.94L4.07 10.08c-.83.35-.81 1.53.02 1.85L9.43 14c.26.1.47.31.57.57l2.06 5.33c.32.84 1.51.86 1.86.03l6.15-14.67c.33-.83-.5-1.66-1.32-1.32z" />
      </svg>

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        height="28px"
        viewBox="0 0 24 24"
        width="28px"
        fill="#15803d"
        //  fill="#FFFFFF"
      >
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" />
      </svg> */}

      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        enableBackground="new 0 0 24 24"
        height="48px"
        viewBox="0 0 24 24"
        width="48px"
        //        fill="#FFFFFF"
        fill="#15803d"
      >
        <g>
          <path d="M0,0h24v24H0V0z" fill="none" />
        </g>
        <g>
          <g>
            <path d="M13.02,19.93v2.02c2.01-0.2,3.84-1,5.32-2.21l-1.42-1.43C15.81,19.17,14.48,19.75,13.02,19.93z" />
            <path d="M4.03,12c0-4.05,3.03-7.41,6.95-7.93V2.05C5.95,2.58,2.03,6.84,2.03,12c0,5.16,3.92,9.42,8.95,9.95v-2.02 C7.06,19.41,4.03,16.05,4.03,12z" />
            <path d="M19.95,11h2.02c-0.2-2.01-1-3.84-2.21-5.32l-1.43,1.43C19.19,8.21,19.77,9.54,19.95,11z" />
            <path d="M18.34,4.26c-1.48-1.21-3.32-2.01-5.32-2.21v2.02c1.46,0.18,2.79,0.76,3.9,1.62L18.34,4.26z" />
            <path d="M18.33,16.9l1.43,1.42c1.21-1.48,2.01-3.31,2.21-5.32h-2.02C19.77,14.46,19.19,15.79,18.33,16.9z" />
            <path d="M16,11.1C16,8.61,14.1,7,12,7s-4,1.61-4,4.1c0,1.66,1.33,3.63,4,5.9C14.67,14.73,16,12.76,16,11.1z M12,12 c-0.59,0-1.07-0.48-1.07-1.07c0-0.59,0.48-1.07,1.07-1.07s1.07,0.48,1.07,1.07C13.07,11.52,12.59,12,12,12z" />
          </g>
        </g>
      </svg> */}
    </div>
  );
};

export default LocButton;
