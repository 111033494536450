import React, {
  useCallback,
  useContext,
  useRef,
  useState,
  useEffect,
} from "react";
import WiAddress from "../context/WiAddress";
import AutoCompleteResult from "./AutoCompleteResult";

const addSpace = (str) => {
  if (str != "")
    return str
      .replaceAll(".", "")
      .replaceAll(" ", "")
      .split("")
      .slice(0, 4)
      .join(".");
  else return "";
};

//Merge result and remove duplicates
function mergeAutocomplete(result1, result2) {
  // //console.log(result1);
  // //console.log(result2);

  let result3 = [...result1];

  for (let i = 0; i < result2.length; i++) {
    let duplicate = false;
    for (let j = 0; j < result1.length; j++) {
      // //console.log(result2[i]);
      if (
        result2[i].commune === result1[j].commune &&
        result2[i].wilaya === result1[j].wilaya
      )
        duplicate = true;
    }
    if (!duplicate) {
      result3.push(result2[i]);
    }
  }

  return result3;
}

const Search = (props) => {
  const villeWi = useRef();
  const codeWi = useRef();
  const ctx = useContext(WiAddress);

  const list_names = [
    {
      search_text1: "Wi",
      search_text2: "Adresse",
      search_text3: "Commune",
      search_text4: "Code",

      search_text5: "Renseignez le code (exemple",
      search_text6: "Aucun résultat",
      search_text7: "(vérifiez le nom de la",
      search_text8: "Commune",

      css_direction: "ltr",
      css_1: " font-PermanentMarker ",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-r-full pr-2 border-r-2 ",
      css_4: " rounded-l-full pl-2 ",
    },
    {
      search_text1: "<وي>",
      search_text2: "عنوان",
      search_text3: "البلدية",
      search_text4: "الرمز",

      search_text5: "يرجى إدخال الرمز (مثال",
      search_text6: "ليس هناك أي نتائج",
      search_text7: "(تحقق من اسم",
      search_text8: "البلدية",

      css_direction: "rtl",
      css_1: "",
      css_2: " rounded-full pr-4 ",
      css_3: " rounded-l-full pl-2 border-l-2 ",
      css_4: " rounded-r-full pl-2 ",
    },
    {
      search_text1: "Wi",
      search_text2: "Address",
      search_text3: "Town",
      search_text4: "Code",

      search_text5: "Provide your code (example",
      search_text6: "No results",
      search_text7: "(verify the name of the",
      search_text8: "Town",

      css_direction: "ltr",
      css_1: " font-PermanentMarker ",
      css_2: " rounded-full pl-4 ",
      css_3: " rounded-r-full pr-2 border-r-2 ",
      css_4: " rounded-l-full pl-2 ",
    },
  ];

  const [autocomplete, setAutocomplete] = useState([]);
  const [villeSelected, setVilleSelected] = useState(false);
  const [code, setCode] = useState("");

  const [activateNoResult, setActivateNoResult] = useState(true);

  const [seprator, setSeparator] = useState("");

  useEffect(() => {
    if (ctx.language == 1) {
      setSeparator("،");
      if (villeWi.current.value != "") {
        if (ctx.commune_ar != "") {
          villeWi.current.value = ctx.commune_ar + "،";
        } else {
          villeWi.current.value = ctx.commune + "،";
        }
      }
    } else {
      setSeparator(",");
      if (villeWi.current.value != "") {
        villeWi.current.value = ctx.commune + ",";
      }
    }

    if (villeWi.current.value != "") {
      codeWi.current.value = addSpace(ctx.code);
    }
  }, [ctx.language, ctx.commune, ctx.commune_ar, ctx.code]);

  const setInputValue = (val) => {
    let wiVal = val.split(/,|،/);

    if (wiVal[1] != "") {
      villeWi.current.value = "";
      codeWi.current.value = "";
      setAutocomplete([]);
      setVilleSelected(false);
    } else {
      //console.log(wiVal[0] + seprator);

      villeWi.current.value = wiVal[0] + seprator;
      //console.log(villeWi.current.value);
      setAutocomplete([]);
      // villeWi.current.focus();
      codeWi.current.focus();
      setVilleSelected(true);
      setActivateNoResult(false);
    }
  };

  const onChangeCodeHandler = useCallback((event) => {
    codeWi.current.value = addSpace(
      codeWi.current.value.replace(/[\u{0080}-\u{FFFF}]/gu, "")
    );
  });

  const onChangeHandler = useCallback((event) => {
    // console.log("input: " + villeWi.current.value);
    villeWi.current.value = villeWi.current.value.trimStart();
    villeWi.current.value = villeWi.current.value.replaceAll("  ", " ");
    let inputValues = villeWi.current.value.split(/,|،/);
    let inputCommune = inputValues[0];

    setActivateNoResult(true);
    setVilleSelected(false);
    //console.log(inputCommune);

    if (inputValues.length > 1 && inputValues[1] != "") {
      setCode(
        inputValues[1]
          .replaceAll(" ", "")
          .replaceAll(".", "")
          .replace(/[\u{0080}-\u{FFFF}]/gu, "")
      );

      //console.log(inputValues[1].replaceAll(" ", "").replaceAll(".", ""));
      villeWi.current.value = inputValues[0] + seprator;
      codeWi.current.value = inputValues[1]
        .replaceAll(" ", "")
        .replace(/[\u{0080}-\u{FFFF}]/gu, "");
    } else {
      setCode("");
    }

    //http request for autocomplete
    if (
      inputCommune !== ""
      // &&
      // (inputValues.length < 2 || villeSelected === false)
    ) {
      fetch(ctx.host + "/autocomplete/" + inputCommune + "," + ctx.pays)
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            setAutocomplete([]);
            return;
          }

          // Set the response
          response.json().then((data) => {
            // handle the case where there is spaces
            if (inputCommune.includes(" ")) {
              //console.log("space");

              let call2 = async () => {
                let response2 = await fetch(
                  ctx.host +
                    "/autocomplete/" +
                    inputCommune.replaceAll(" ", "-") +
                    "," +
                    ctx.pays
                );
                if (response2.status !== 200) {
                  console.log(
                    "Looks like there was a problem. Status Code: " +
                      response.status
                  );
                  setAutocomplete([]);
                  return;
                }
                let data2 = await response2.json();
                let final_result = mergeAutocomplete(data, data2);
                //console.log(final_result);
                setAutocomplete(final_result);
              };
              call2();
            }
            // handle the case where there is dash
            else if (inputCommune.includes("-")) {
              //console.log("dash");

              let call2 = async () => {
                let response2 = await fetch(
                  ctx.host +
                    "/autocomplete/" +
                    inputCommune.replaceAll("-", " ") +
                    "," +
                    ctx.pays
                );
                if (response2.status !== 200) {
                  console.log(
                    "Looks like there was a problem. Status Code: " +
                      response.status
                  );
                  setAutocomplete([]);
                  return;
                }
                let data2 = await response2.json();
                let final_result = mergeAutocomplete(data, data2);
                //console.log(final_result);
                setAutocomplete(final_result);
              };
              call2();
            } else {
              //console.log("no space or dash " + JSON.stringify(data));
              setAutocomplete(data);
            }
          });
        })
        .catch(function (err) {
          //console.log("Fetch Error :-S", err);
          setAutocomplete([]);
        });
    } else {
      setAutocomplete([]);
      if (inputValues.length < 2) {
        setVilleSelected(false);
      }
      if (inputCommune === "") {
        setCode("");
      }
    }
  });

  const onSearchClickHandler = useCallback(async (event) => {
    //console.log("input Click: " + villeWi.current.value);

    let inputValues = villeWi.current.value.split(",");
    let inputCommune = inputValues[0];
    let inputCode = codeWi.current.value
      .replaceAll(" ", "")
      .replaceAll(".", "")
      .replace(/[\u{0080}-\u{FFFF}]/gu, "");

    console.log("input code: ", inputCode);

    // if (inputValues.length > 1) {
    //   inputCode = inputValues[1].replaceAll(" ", "");
    // }

    if (villeSelected === true) {
      let response = await fetch(
        ctx.host +
          "/geocoder?address=" +
          ctx.commune +
          "," +
          ctx.postal_code +
          "," +
          inputCode
      );
      if (response.status !== 200) {
        console.log(
          "Looks like there was a problem. Status Code: " + response.status
        );
        return;
      }
      let data = await response.json();

      //console.log(data);

      ctx.setCommune(data.properties.name);
      ctx.setCommune_ar(data.properties.name_ar);
      let code_array = data.properties.code;
      let code = "";
      // code_array[0] + code_array[1] + code_array[2] + code_array[3];
      for (let i = 0; i < code_array.length; i++) {
        code += code_array[i];
      }
      //      console.log("code_searche:" + code);
      ctx.setCode(code);
      ctx.setPostal_code(data.properties.wilaya);
      ctx.setPays(data.properties.pays);
      // ctx.setLatitude(data.geometry.coordinates[1]);
      // ctx.setLongitute(data.geometry.coordinates[0]);
      ctx.setLongLat({
        long: data.geometry.coordinates[0],
        lat: data.geometry.coordinates[1],
      });

      ctx.setZoom(16);
      villeWi.current.value = "";
      codeWi.current.value = "";
      setVilleSelected(false);
    }
  });

  const onKeyDownHandler = useCallback((event) => {
    if (event.key === "Enter") {
      //      //console.log("Enter key pressed ");
      codeWi.current.blur();
      onSearchClickHandler(event);
    }
  });

  let searchButtonStyle =
    list_names[ctx.language].css_3 +
    " h-11  w-12 p-1  my-auto  border-y-2  border-green-700 ";

  if (villeSelected === true) {
    searchButtonStyle =
      searchButtonStyle + " " + "bg-green-700  hover:cursor-pointer ";
  } else {
    searchButtonStyle =
      searchButtonStyle +
      " " +
      "bg-green-700  hover:cursor-not-allowed opacity-50";
  }

  return (
    <div className="" dir={list_names[ctx.language].css_direction}>
      <div
        className="flex justify-center border-b-2 border-green-700 mx-2 w-10/12"
        dir={list_names[ctx.language].css_direction}
      >
        <span className="font-bold text-sm px-2  rounded-t-lg  border-green-700 bg-green-700 text-white">
          <span className={" px-1 " + list_names[ctx.language].css_1}>
            {list_names[ctx.language].search_text1}
          </span>
          {list_names[ctx.language].search_text2}
        </span>
      </div>
      <div
        className="flex  h-12 justify-around"
        dir={list_names[ctx.language].css_direction}
      >
        <input
          className={
            " font-bold p-2   h-11 my-auto w-7/12 border-2   border-gray-400 focus:outline-none focus:border-green-700 focus:ring-green-700 focus:ring-1 placeholder:italic placeholder:text-slate-400 placeholder:text-center placeholder:font-bold dark:opacity-90 " +
            list_names[ctx.language].css_2
          }
          placeholder={list_names[ctx.language].search_text3}
          spellCheck="false"
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          ref={villeWi}
        />
        <div className="flex">
          <input
            className={
              " uppercase font-bold p-1  h-11 my-auto  w-20  border-2  border-gray-400 focus:outline-none focus:border-green-700 focus:ring-green-700 focus:ring-1 placeholder:italic placeholder:text-slate-400 placeholder:text-center placeholder:font-bold text-blue-800 dark:opacity-90 " +
              list_names[ctx.language].css_4
            }
            placeholder={list_names[ctx.language].search_text4}
            spellCheck="false"
            onChange={onChangeCodeHandler}
            onKeyDown={onKeyDownHandler}
            ref={codeWi}
            dir="ltr"
          />

          <div className={searchButtonStyle} onClick={onSearchClickHandler}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="36px"
              viewBox="0 0 24 24"
              width="36px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0V0z" fill="none" />
              <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
            </svg>
          </div>
        </div>
      </div>
      {codeWi &&
        codeWi.current &&
        document.activeElement === codeWi.current &&
        codeWi.current.value === "" && (
          <div
            className="flex justify-end opacity-90"
            dir={list_names[ctx.language].css_direction}
          >
            <ul className="bg-white flex flex-col justify-end rounded-full shadow-lg  overflow-auto p-2 border-red-700 border-2">
              <li className=" text-center text-gray-800">
                {list_names[ctx.language].search_text5 + " :"}&nbsp;
                <span className="font-bold text-blue-800">F.2.4.2</span>)
              </li>
            </ul>
          </div>
        )}

      <div className=" " dir={list_names[ctx.language].css_direction}>
        <ul className="bg-white w-11/12 mx-auto flex flex-col max-h-80 overflow-auto rounded-lg">
          {autocomplete.length === 0 &&
            villeWi &&
            villeWi.current &&
            villeWi.current.value != "" &&
            activateNoResult && (
              <li className="p-2 hover:bg-gray-300">
                <span className="font-medium">
                  {list_names[ctx.language].search_text6}{" "}
                </span>
                <span className="text-sm">
                  {list_names[ctx.language].search_text7}&nbsp;
                  <span className="font-bold underline ">
                    {list_names[ctx.language].search_text8}
                  </span>
                  )
                </span>
              </li>
            )}
          {villeWi &&
            villeWi.current &&
            villeWi.current.value != "" &&
            autocomplete.map((result) => {
              if (result.commune && result.pays && result.wilaya) {
                if (result.poi) {
                  return (
                    <AutoCompleteResult
                      key={result.wilaya + result.commune + result.name}
                      commune={result.commune}
                      commune_ar={result.commune_ar}
                      code={result.code}
                      postal_code={result.wilaya}
                      pays={result.pays}
                      poi={result.poi}
                      name={result.name}
                      name_ar={result.name_ar}
                      setInputValue={setInputValue}
                      openDialog={props.openDialog}
                      openShareDialog={props.openShareDialog}
                    />
                  );
                } else {
                  return (
                    <AutoCompleteResult
                      key={result.wilaya + result.commune}
                      commune={result.commune}
                      commune_ar={result.commune_ar}
                      code={code}
                      postal_code={result.wilaya}
                      pays={result.pays}
                      setInputValue={setInputValue}
                    />
                  );
                }
              }
            })}
          {/* <AutoCompleteResult />
          <AutoCompleteResult />
          <AutoCompleteResult />
          <AutoCompleteResult />
          <AutoCompleteResult />
          <AutoCompleteResult />
          <AutoCompleteResult />
          <AutoCompleteResult /> */}
        </ul>
      </div>
    </div>
  );
};

export default Search;
