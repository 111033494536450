import { Popup } from "react-leaflet";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Dialog, Transition } from "@headlessui/react";
import WiAddress from "../../context/WiAddress";

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const WiPopup = (props) => {
  const ctx = useContext(WiAddress);

  const [displayedPOICommune, setDisplayedPOICommune] = useState("");
  const [displayedPOIName, setDisplayedPOIName] = useState("");

  const list_names = [
    {
      poi_text1: "Adresse",
      css_direction: "ltr",
      css_1: " text-left ml-1 ",
    },
    {
      poi_text1: "عنوان",
      css_direction: "rtl",
      css_1: " text-right mr-1 ",
    },
    {
      poi_text1: "Address",
      css_direction: "ltr",
      css_1: " text-left ml-1 ",
    },
  ];

  const closePoiDialog = useCallback(() => {
    //console.log("close Share Dialog");
    ctx.setShowPoiDialog(false);
  });

  const openPoiDialog = useCallback((val) => {
    //console.log("open Share Dialog");
    ctx.setShowPoiDialog(true);
  });

  const openNavDialog = useCallback(() => {
    props.openDialog(0, true);
  });

  const openShareDialogHandler = useCallback(() => {
    props.openShareDialog(true);
  });

  useEffect(() => {
    if (ctx.language == 1) {
      if (ctx.poiWiAddressTown_ar && ctx.poiWiAddressTown_ar != "") {
        setDisplayedPOICommune(ctx.poiWiAddressTown_ar);
      } else {
        setDisplayedPOICommune(ctx.poiWiAddressTown);
      }

      if (ctx.poiName_ar && ctx.poiName_ar != "") {
        setDisplayedPOIName(ctx.poiName_ar);
      } else {
        setDisplayedPOIName(ctx.poiName);
      }
    } else {
      setDisplayedPOIName(ctx.poiName);
      setDisplayedPOICommune(ctx.poiWiAddressTown);
    }
  }, [
    ctx.language,
    ctx.poiWiAddressTown,
    ctx.poiWiAddressTown_ar,
    ctx.poiName,
    ctx.poiName_ar,
  ]);

  return (
    <Transition appear show={ctx.showPoiDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={() => {}}

        // onClose={closePoiDialog}
      >
        <div className="min-h-screen px-2 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-9/12 max-w-xs  my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-gray-100 border-2">
              <Dialog.Title as="h3" className="">
                <div className="flex justify-end px-1 pt-1">
                  <div
                    className="h-6 w-6 border-2 rounded-md border-white hover:border-gray-500 hover:cursor-pointer flex"
                    onClick={closePoiDialog}
                  >
                    <svg
                      className="m-auto"
                      width="16"
                      height="16"
                      viewBox="0 0 10 10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.728 4.738L9.475.99 8.485 0 4.738 3.748.99 0 0 .99l3.748 3.748L0 8.485l.99.99 3.748-3.747 3.747 3.747.99-.99-3.747-3.747z"
                        fill="#999"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Dialog.Title>
              <div
                className="mx-2 mb-2"
                dir={list_names[ctx.language].css_direction}
              >
                <ul className="flex flex-col justify-between h-full w-full ">
                  <li className="flex max-h-56 ">
                    <img
                      className="rounded-t-lg"
                      src={"https://www.WiMapper.com/images/" + ctx.poiImage}
                    />
                  </li>
                  <li className="flex flex-col bg-black p-1">
                    <span className="font-semibold text-base text-center text-white">
                      {displayedPOIName}
                    </span>
                  </li>
                  <li className="flex flex-col mb-2">
                    <span className="font-semibold text-lg text-center text-green-700 border-b-2 border-black">
                      <div className="flex justify-center p-1">
                        <div>
                          <img src="../../wi.png" height="25px" width="25px" />
                        </div>
                        <div className="ml-1 pt-0">
                          {" "}
                          {list_names[ctx.language].poi_text1}
                        </div>
                      </div>
                    </span>
                    <div className="flex justify-center mx-auto p-1 text-sm border-b-2 border-black w-full">
                      <div className="font-semibold ">
                        {displayedPOICommune}
                        {ctx.language != 1 ? "," : "،"}
                      </div>
                      <div
                        className="font-bold text-md text-blue-800  px-1 tracking-widest"
                        dir="ltr"
                      >
                        {addSpace(ctx.poiWiAddressCode)}
                      </div>
                    </div>
                  </li>

                  {/* Display Phone Number */}
                  {ctx.poiPhoneNumber != "" && (
                    <li className="flex justify-start text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="20px"
                        height="20px"
                      >
                        <path d="M 14 3.9902344 C 8.4886661 3.9902344 4 8.4789008 4 13.990234 L 4 35.990234 C 4 41.501568 8.4886661 45.990234 14 45.990234 L 36 45.990234 C 41.511334 45.990234 46 41.501568 46 35.990234 L 46 13.990234 C 46 8.4789008 41.511334 3.9902344 36 3.9902344 L 14 3.9902344 z M 18.005859 12.033203 C 18.633859 12.060203 19.210594 12.414031 19.558594 12.957031 C 19.954594 13.575031 20.569141 14.534156 21.369141 15.785156 C 22.099141 16.926156 22.150047 18.399844 21.498047 19.589844 L 20.033203 21.673828 C 19.637203 22.237828 19.558219 22.959703 19.824219 23.595703 C 20.238219 24.585703 21.040797 26.107203 22.466797 27.533203 C 23.892797 28.959203 25.414297 29.761781 26.404297 30.175781 C 27.040297 30.441781 27.762172 30.362797 28.326172 29.966797 L 30.410156 28.501953 C 31.600156 27.849953 33.073844 27.901859 34.214844 28.630859 C 35.465844 29.430859 36.424969 30.045406 37.042969 30.441406 C 37.585969 30.789406 37.939797 31.366141 37.966797 31.994141 C 38.120797 35.558141 35.359641 37.001953 34.556641 37.001953 C 34.000641 37.001953 27.316344 37.761656 19.777344 30.222656 C 12.238344 22.683656 12.998047 15.999359 12.998047 15.443359 C 12.998047 14.640359 14.441859 11.879203 18.005859 12.033203 z" />
                      </svg>
                      <span
                        className={
                          "text-blue-800 truncate w-11/12 " +
                          list_names[ctx.language].css_1
                        }
                        dir="ltr"
                      >
                        <a href={"tel:" + ctx.poiPhoneNumber}>
                          {ctx.poiPhoneNumber}
                        </a>
                      </span>
                    </li>
                  )}

                  {/* Display Email */}
                  {ctx.poiEmail != "" && (
                    <li className="flex justify-start text-sm ">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 50 50"
                        width="20px"
                        height="20px"
                      >
                        <path d="M 14 4 C 8.4886661 4 4 8.4886661 4 14 L 4 36 C 4 41.511334 8.4886661 46 14 46 L 36 46 C 41.511334 46 46 41.511334 46 36 L 46 14 C 46 8.4886661 41.511334 4 36 4 L 14 4 z M 13 16 L 37 16 C 37.18 16 37.349766 16.020312 37.509766 16.070312 L 27.679688 25.890625 C 26.199688 27.370625 23.790547 27.370625 22.310547 25.890625 L 12.490234 16.070312 C 12.650234 16.020312 12.82 16 13 16 z M 11.070312 17.490234 L 18.589844 25 L 11.070312 32.509766 C 11.020312 32.349766 11 32.18 11 32 L 11 18 C 11 17.82 11.020312 17.650234 11.070312 17.490234 z M 38.929688 17.490234 C 38.979688 17.650234 39 17.82 39 18 L 39 32 C 39 32.18 38.979687 32.349766 38.929688 32.509766 L 31.400391 25 L 38.929688 17.490234 z M 20 26.410156 L 20.890625 27.310547 C 22.020625 28.440547 23.510234 29 24.990234 29 C 26.480234 29 27.959844 28.440547 29.089844 27.310547 L 29.990234 26.410156 L 37.509766 33.929688 C 37.349766 33.979688 37.18 34 37 34 L 13 34 C 12.82 34 12.650234 33.979687 12.490234 33.929688 L 20 26.410156 z" />
                      </svg>
                      <span
                        className={
                          " truncate w-11/12 " + list_names[ctx.language].css_1
                        }
                        dir="ltr"
                      >
                        <a href={"mailto:" + ctx.poiEmail}>{ctx.poiEmail}</a>
                      </span>
                    </li>
                  )}

                  {/* Display Website */}
                  {ctx.poiWebsite != "" && (
                    <li className="flex justify-start text-sm">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="20"
                        viewBox="0 -960 960 960"
                        width="20"
                      >
                        <path d="M480-80q-84 0-157-31.5T196-197q-54-54-85-127.5T80-482q0-84 31-156.5T196-765q54-54 127-84.5T480-880q84 0 157 30.5T764-765q54 54 85 126.5T880-482q0 84-31 157.5T764-197q-54 54-127 85.5T480-80Zm0-58q35-36 58.5-82.5T577-331H384q14 60 37.5 108t58.5 85Zm-85-12q-25-38-43-82t-30-99H172q38 71 88 111.5T395-150Zm171-1q72-23 129.5-69T788-331H639q-13 54-30.5 98T566-151ZM152-391h159q-3-27-3.5-48.5T307-482q0-25 1-44.5t4-43.5H152q-7 24-9.5 43t-2.5 45q0 26 2.5 46.5T152-391Zm221 0h215q4-31 5-50.5t1-40.5q0-20-1-38.5t-5-49.5H373q-4 31-5 49.5t-1 38.5q0 21 1 40.5t5 50.5Zm275 0h160q7-24 9.5-44.5T820-482q0-26-2.5-45t-9.5-43H649q3 35 4 53.5t1 34.5q0 22-1.5 41.5T648-391Zm-10-239h150q-33-69-90.5-115T565-810q25 37 42.5 80T638-630Zm-254 0h194q-11-53-37-102.5T480-820q-32 27-54 71t-42 119Zm-212 0h151q11-54 28-96.5t43-82.5q-75 19-131 64t-91 115Z" />
                      </svg>
                      <span
                        className={
                          " text-blue-800 truncate  w-11/12 text-left ml-1" +
                          list_names[ctx.language].css_1
                        }
                        dir="ltr"
                      >
                        <a href={"https://" + ctx.poiWebsite} target="_blank">
                          {ctx.poiWebsite}
                        </a>
                      </span>
                    </li>
                  )}

                  {/* Display action Buttons */}
                  <li>
                    <div className="flex mt-1 justify-around  pt-2">
                      <div
                        className="h-8 w-8 my-auto bg-cyan-600 rounded-full  hover:bg-cyan-500 hover:cursor-pointer hover:focus:shadow-md"
                        onClick={openShareDialogHandler}
                      >
                        <span className="flex w-full h-full ">
                          <svg
                            className="m-auto"
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 0 24 24"
                            width="24px"
                            fill="#FFFFFF"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
                          </svg>
                        </span>
                      </div>
                      <div
                        className=" h-8 w-8 bg-green-700  rounded-lg my-auto hover:bg-green-600 hover:cursor-pointer hover:focus:shadow-md"
                        onClick={openNavDialog}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          height="30px"
                          viewBox="0 0 24 24"
                          width="30px"
                          fill="#FFFFFF"
                          className="mx-auto"
                        >
                          <path d="M0 0h24v24H0z" fill="none" />
                          <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z" />
                        </svg>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default WiPopup;
