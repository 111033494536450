import React, { useContext, Fragment } from "react";

import { Dialog, Transition } from "@headlessui/react";
import Navigation from "./BoxContent/Navigation";
import Contact from "./BoxContent/Contact";
import AboutUs from "./BoxContent/AboutUs";
import Tutorial from "./BoxContent/Tutorial";
import Localization from "./BoxContent/Localization";
import HowTo from "./BoxContent/HowTo2";

import WiAddress from "../context/WiAddress";

const DialogBox = (props) => {
  let title = "";
  let content;

  const ctx = useContext(WiAddress);

  const list_names = [
    {
      title_navigation: "S'y rendre",
      title_tutorial: "Tutoriel",
      title_aboutUs: "À propos",
      title_contact: "N'hésitez pas à nous écrire !",
      // title_howTo: "Voici votre Wi-Adresse !",
      title_howTo: "Trouver le lieu d'une Wi-Adresse",
      title_localization: "Identifier votre Wi-Adresse !",
      css_direction: "ltr",
    },
    {
      title_navigation: "قيادة",
      title_tutorial: "شرح برنامجنا",
      title_aboutUs: "تعرف علينا",
      title_contact: "تواصل معنا",
      // title_howTo: "هاهو<وي>عنوانك",
      title_howTo: "تحديد الموقع باستخدام الوي عنوان",
      title_localization: "تعرف على <وي>عنوانك",
      css_direction: "rtl",
    },
    {
      title_navigation: "Navigation",
      title_tutorial: "Tutorial",
      title_aboutUs: "About Us",
      title_contact: "Contact Us",
      // title_howTo: "Here is your Wi-Address !",
      title_howTo: "Find the location of a Wi-Address",
      title_localization: "Identify your Wi-Address !",
      css_direction: "ltr",
    },
  ];

  if (props.titleDialog === 0) {
    title = list_names[ctx.language].title_navigation;
    content = <Navigation showPoiVal={props.showPoiVal}></Navigation>;
  } else if (props.titleDialog === 1) {
    title = list_names[ctx.language].title_tutorial;
    content = <Tutorial></Tutorial>;
  } else if (props.titleDialog === 2) {
    title = list_names[ctx.language].title_aboutUs;
    content = <AboutUs></AboutUs>;
  } else if (props.titleDialog === 3) {
    title = list_names[ctx.language].title_contact;
    content = <Contact></Contact>;
  } else if (props.titleDialog === 4) {
    title = list_names[ctx.language].title_howTo;
    content = <HowTo closeDialog={props.closeDialog}></HowTo>;
  }
  // value == -1
  else {
    title = list_names[ctx.language].title_localization;
    content = <Localization closeDialog={props.closeDialog}></Localization>;
  }

  return (
    <Transition appear show={props.showDialog} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        onClose={props.closeDialog}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl border-gray-100 border-2">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                <div className="flex justify-between">
                  <div></div>
                  <div className="my-2">{title}</div>
                  <div
                    className="h-6 w-6 border-2 rounded-md border-white hover:border-gray-500 hover:cursor-pointer flex"
                    onClick={props.closeDialog}
                  >
                    <svg
                      className="m-auto"
                      width="16"
                      height="16"
                      viewBox="0 0 10 10"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.728 4.738L9.475.99 8.485 0 4.738 3.748.99 0 0 .99l3.748 3.748L0 8.485l.99.99 3.748-3.747 3.747 3.747.99-.99-3.747-3.747z"
                        fill="#999"
                        fillRule="evenodd"
                      />
                    </svg>
                  </div>
                </div>
              </Dialog.Title>
              {content}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DialogBox;
