import React, { useCallback, useContext, useState, useEffect } from "react";
import WiAddress from "../context/WiAddress";

const returnCode = (code) => {
  if (code !== "9JJJ") return code;
  else return "";
};

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const Address = (props) => {
  const ctx = useContext(WiAddress);
  const [displayedCommune, setDisplayedCommune] = useState("");
  const [seprator, setSeparator] = useState("");

  const list_names = [
    {
      address_text1: "Adresse",
      css_direction: "ltr",
      css_1: " text-left ml-1 ",
    },
    {
      address_text1: "عنوان",
      css_direction: "rtl",
      css_1: " text-right mr-1 ",
    },
    {
      address_text1: "Address",
      css_direction: "ltr",
      css_1: " text-left ml-1 ",
    },
  ];

  const [button_clicked, setButtonClicked] = useState(false);

  const openNavDialog = useCallback(() => {
    props.openDialog(0, false);
  });

  const openShareDialogHandler = useCallback(() => {
    setButtonClicked(true);
    props.openShareDialog(false);
  });

  useEffect(() => {
    if (ctx.language == 1) {
      setSeparator("،");
      if (ctx.commune_ar !== "") {
        setDisplayedCommune(ctx.commune_ar);
      } else {
        setDisplayedCommune(ctx.commune);
      }
    } else {
      setSeparator(",");
      setDisplayedCommune(ctx.commune);
    }
  }, [ctx.language, ctx.commune, ctx.commune_ar]);

  const share = (
    // <svg
    //   xmlns="http://www.w3.org/2000/svg"
    //   height="24px"
    //   viewBox="0 0 24 24"
    //   width="24px"
    //   fill="#000000"
    // >
    //   <path d="M0 0h24v24H0z" fill="none" />
    //   <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#FFFFFF"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z" />
    </svg>
  );

  const wiaddress = (
    <>
      <span className="font-semibold text-xl border-2 rounded-t-lg border-green-700 bg-green-700 text-white">
        {/* <span className="font-PermanentMarker pr-1">Wi</span> */}
        <div className="flex justify-center">
          <div>
            <img src="../../wi.png" height="25px" width="25px" />
          </div>
          <div className="ml-1 px-1 ">
            {list_names[ctx.language].address_text1}
          </div>
        </div>
      </span>

      <span className="flex justify-center font-semibold p-2 ">
        {displayedCommune} {seprator}
        <span className="font-bold  text-blue-800 px-1 " dir="ltr">
          {addSpace(returnCode(ctx.code))}
        </span>
      </span>
      <span className="font-light text-sm text-gray-700 p-0.5 border-green-700 border-t-2 italic">
        {"("}
        {ctx.postal_code} {seprator + " "} {ctx.pays}
        {")"}
      </span>
    </>
  );

  const navigate = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48px"
      viewBox="0 0 24 24"
      width="48px"
      fill="#FFFFFF"
      className="mx-auto"
    >
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M18.92 6.01C18.72 5.42 18.16 5 17.5 5h-11c-.66 0-1.21.42-1.42 1.01L3 12v8c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-1h12v1c0 .55.45 1 1 1h1c.55 0 1-.45 1-1v-8l-2.08-5.99zM6.5 16c-.83 0-1.5-.67-1.5-1.5S5.67 13 6.5 13s1.5.67 1.5 1.5S7.33 16 6.5 16zm11 0c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5zM5 11l1.5-4.5h11L19 11H5z" />
    </svg>
  );

  let common_style_1 =
    "my-auto bg-cyan-600 rounded-full p-4 h-14 hover:bg-cyan-500 hover:cursor-pointer hover:focus:shadow-md";
  let common_style_2 =
    "bg-green-700 h-14 rounded-lg my-auto hover:bg-green-600 hover:cursor-pointer hover:focus:shadow-md";

  //larger screen view
  // if (props.breakpoint === "true") {
  //   return (
  //     <div className="w-80 h-44 max-w-sm flex flex-col bg-blue-600">
  //       <div className="bg-white w-11/12 mx-auto mt-2  rounded-lg flex flex-col  text-center">
  //         {wiaddress}
  //       </div>
  //       <div className="flex justify-evenly mt-2">
  //         <div className={` ${common_style_1}`}>{share}</div>
  //         <div className={` ${common_style_2} w-16`}>{navigate}</div>
  //       </div>
  //     </div>
  //   );
  // }

  //Smartphone view
  return (
    ctx.commune.length > 0 && (
      <div className="w-full max-w-lg mx-auto flex content-center ">
        <div
          className={` ${common_style_1} shadow-xl shadow-black/50 flex `}
          onClick={openShareDialogHandler}
        >
          {!button_clicked && (
            <span className="motion-safe:animate-[ping_2s_ease-out_infinite] absolute inline-flex h-6 w-6 border-8 border-gray-200 rounded-full bg-sky-400 opacity-75"></span>
          )}
          <span className="relative inline-flex">{share}</span>
        </div>

        <div
          className=" bg-white w-4/5 ml-1 my-auto rounded-lg flex flex-col  text-center  shadow-xl shadow-black/50"
          dir={list_names[ctx.language].css_direction}
        >
          {wiaddress}
        </div>

        <div
          className={` ${common_style_2}  w-1/5 ml-1 shadow-xl shadow-black/50`}
          onClick={openNavDialog}
        >
          {navigate}
        </div>
      </div>
    )
  );
};

export default Address;
