import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";

import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/solid";

import WiAddress from "../context/WiAddress";

const list_languages = [
  { name: "Français" },
  { name: "العربية" },
  { name: "English" },
];

const Language = (props) => {
  const ctx = useContext(WiAddress);

  const [selected, setSelected] = useState(list_languages[ctx.language]);

  //Save language choice in the context variable
  useEffect(() => {
    // if language is not set, show popup for selecting language, otherwise set language
    let language = window.localStorage.getItem("Lang");
    if (language) {
      list_languages.map((lang, langIdx) => {
        if (lang === selected && ctx.language != langIdx) {
          ctx.setLanguage(langIdx);
          window.localStorage.setItem("Lang", langIdx);
        }
      });
    }
  }, [selected]);

  useEffect(() => {
    // if context variable changes
    list_languages.map((lang, langIdx) => {
      if (lang === selected && ctx.language != langIdx) {
        setSelected(list_languages[ctx.language]);
      }
    });
  }, [ctx.language]);

  return (
    <div className="">
      <Listbox value={selected} onChange={setSelected}>
        <div className="relative mt-1 mr-1">
          <Listbox.Button className="relative w-full cursor-default bg-white rounded-b-lg rounded-l-lg pl-2 pb-1 pr-5 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            <span className="block truncate">{selected.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center ">
              <ChevronDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className=" mt-1 max-h-60 w-30 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {list_languages.map((lang, langIdx) => (
                <Listbox.Option
                  key={langIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={lang}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${
                          selected ? "font-medium" : "font-normal"
                        }`}
                      >
                        {lang.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default Language;
