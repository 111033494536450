import React, { useContext, useState, useEffect } from "react";
import WiAddress from "../../context/WiAddress";

import Maps from "../../g-map-ic.png";
import Waze from "../../waze.png";

const addSpace = (str) => {
  if (str != "")
    return str.replaceAll(".", "").replaceAll(" ", "").split("").join(".");
  else return "";
};

const Navigation = (props) => {
  const ctx = useContext(WiAddress);

  const [commune, setCommune] = useState("");
  const [commune_ar, setCommune_ar] = useState("");
  const [displayedCommune, setDisplayedCommune] = useState("");
  const [seprator, setSeparator] = useState("");

  const [code, setCode] = useState("");

  const [longlat, setLongLat] = useState({
    long: 4.034543036977994,
    lat: 36.70982219645621,
  });

  useEffect(() => {
    if (props.showPoiVal) {
      setCommune(ctx.poiWiAddressTown);
      setCommune_ar(ctx.poiWiAddressTown_ar);
      setCode(ctx.poiWiAddressCode);
      setLongLat(ctx.PoiLonglat);

      if (ctx.language == 1) {
        setSeparator("،");
        if (ctx.poiWiAddressTown_ar && ctx.poiWiAddressTown_ar != "") {
          setDisplayedCommune(ctx.poiWiAddressTown_ar);
        } else {
          setDisplayedCommune(ctx.poiWiAddressTown);
        }
      } else {
        setSeparator(",");
        setDisplayedCommune(ctx.poiWiAddressTown);
      }
    } else {
      setCommune(ctx.commune);
      setCommune_ar(ctx.commune_ar);
      setCode(ctx.code);
      setLongLat(ctx.longlat);

      if (ctx.language == 1) {
        setSeparator("،");
        if (ctx.commune_ar && ctx.commune_ar != "") {
          setDisplayedCommune(ctx.commune_ar);
        } else {
          setDisplayedCommune(ctx.commune);
        }
      } else {
        setSeparator(",");
        setDisplayedCommune(ctx.commune);
      }
    }
  }, [
    props.showPoiVal,
    ctx.poiWiAddressTown,
    ctx.poiWiAddressTown_ar,
    ctx.PoiLonglat,
    ctx.poiWiAddressCode,
    ctx.commune,
    ctx.commune_ar,
    ctx.longlat,
    ctx.code,
    ctx.language,
  ]);

  const list_names = [
    {
      navigation_text1: "Naviguez vers",
      css_orientation: " text-center ",
      css_direction: "ltr",
    },
    {
      navigation_text1: "اتّجه إلى",
      css_orientation: " text-center ",
      css_direction: "rtl",
    },
    {
      navigation_text1: "Navigate to",
      css_orientation: " text-center ",
      css_direction: "ltr",
    },
  ];

  return (
    <>
      <div
        className={"mt-2 border-t-2 pt-2"}
        dir={list_names[ctx.language].css_direction}
      >
        <p
          className="flex justify-start h-full w-full text-sm text-gray-700"
          dir={list_names[ctx.language].css_direction}
        >
          {list_names[ctx.language].navigation_text1}
          {" : "}
          <div className="flex mx-1">
            <span className="font-bold">
              {" "}
              {displayedCommune}
              {seprator}
            </span>{" "}
            <span
              className="font-bold text-md px-1 text-blue-800 tracking-widest"
              dir="ltr"
            >
              {addSpace(code)}
            </span>
          </div>
        </p>
      </div>

      <div className="mt-4 flex justify-center gap-10">
        {/* <button
type="button"
className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
onClick={closeDialog}
>
Got it, thanks!
</button> */}
        <div className="h-20 w-20 border-2 rounded-md hover:border-2 hover:cursor-pointer hover:border-gray-700 focus:border-gray-700">
          <a
            target="_blank"
            href={`https://waze.com/ul?ll=${longlat.lat},${longlat.long}&navigate=yes`}
          >
            <img src={Waze}></img>
          </a>
        </div>
        <div className="h-20 w-20 border-2 rounded-md hover:border-2 hover:cursor-pointer hover:border-gray-700 focus:border-gray-700">
          <a
            target="_blank"
            href={`https://www.google.com/maps/dir/?api=1&destination=${longlat.lat},${longlat.long}`}
          >
            <img src={Maps}></img>
          </a>
        </div>
      </div>
    </>
  );
};

export default Navigation;
