import React, { useContext, useState, useCallback } from "react";

import WiMarker from "./WiMarker";
import WiPoi from "./WiPoi";

import { useMapEvents } from "react-leaflet";
import WiAddress from "../../context/WiAddress";

//let pressMapTimer;

const Markers = () => {
  //  const [position, setPosition] = useState([51.505, -0.09]);

  const ctx = useContext(WiAddress);

  const [pois, setPois] = useState([]);

  const map = useMapEvents({
    click(e) {
      //      map.locate();
      //console.log("click +e:" + e.latlng);
    },
    locationfound(e) {
      //console.log("locationfound +e:" + e.latlng);

      if (e.latlng) {
        //        setPosition(e.latlng);
        // ctx.setLatitude(e.latlng.lat);
        // ctx.setLongitute(e.latlng.lng);
        ctx.setLongLat({ long: e.latlng.lng, lat: e.latlng.lat });
        ctx.setZoom(map.getZoom());
        // map.flyTo(
        //   e.latlng,
        //   18
        //   //map.getZoom()
        // );
      }
    },
    dragend() {
      //we do not care about it now
      //console.log("Map dragended");
      //   if (e.latlng) {
      //     setPosition(e.latlng);
      //     map.flyTo(e.latlng, map.getZoom());
      //   }
    },
    mouseup(e) {
      //console.log("mouseup");
      //      clearTimeout(pressMapTimer);
    },
    mousedown(e) {
      //console.log("mousedown");
      //   pressMapTimer = window.setTimeout(function () {
      //     //console.log("put marker");
      //     setPosition(e.latlng);
      //     map.flyTo(
      //       e.latlng,
      //       18
      //       //map.getZoom()
      //     );
      //   }, 600);
    },
    contextmenu(e) {
      //console.log("contextmenu +e:" + e.latlng);
      if (e.latlng) {
        //        setPosition(e.latlng);
        // ctx.setLatitude(e.latlng.lat);
        // ctx.setLongitute(e.latlng.lng);
        // ctx.setLongLat({ long: e.latlng.lng, lat: e.latlng.lat });
        // ctx.setZoom(map.getZoom());

        //http request to get Wi-Address
        fetch(ctx.host + "/reverseGeocode/" + e.latlng.lat + "," + e.latlng.lng)
          .then((response) => {
            if (response.status !== 200) {
              console.log(
                "Looks like there was a problem. Status Code: " +
                  response.status
              );
              return;
            }

            // Examine the text in the response
            response.json().then((data) => {
              //console.log(data);
              //console.log("Commune: " + data.properties.name);
              //console.log("Code: " + data.properties.code);
              //console.log("Postal code: " + data.properties.wilaya);
              let code_array = data.properties.code;
              // let code =
              //   code_array[0] +
              //   code_array[1] +
              //   code_array[2] +
              //   code_array[3] +
              //   code_array[4];

              let code = "";
              for (let i = 0; i < code_array.length; i++) {
                code += code_array[i];
              }

              //console.log("Code: " + code);
              ctx.setCommune(data.properties.name);
              ctx.setCommune_ar(data.properties.name_ar);
              ctx.setCode(code);
              ctx.setPostal_code(data.properties.wilaya);
              ctx.setPays(data.properties.pays);

              ctx.setLongLat({
                long: data.geometry.coordinates[0],
                lat: data.geometry.coordinates[1],
              });

              ctx.setZoom(map.getZoom());
            });
          })
          .catch(function (err) {
            //console.log("Fetch Error :-S", err);
          });

        // map.flyTo(
        //   e.latlng,
        //   //18
        //   map.getZoom()
        // );
      }
    },
    locationerror(e) {
      //console.log("locationerror");
    },

    moveend(e) {
      let lat = map.getCenter().lat;
      let lng = map.getCenter().lng;
      let zoom = Math.floor(map.getZoom());

      // console.log("moveEnd");
      // console.log(map.getCenter());
      // console.log(lat);
      // console.log(lng);
      // console.log(zoom);
      //http request to get Wi-Address
      fetch(
        ctx.host_poi +
          "/getPOI/" +
          lat +
          "," +
          lng +
          "," +
          zoom +
          "," +
          getDistance(zoom)
      )
        .then((response) => {
          if (response.status !== 200) {
            console.log(
              "Looks like there was a problem. Status Code: " + response.status
            );
            return;
          }

          // Examine the text in the response
          response.json().then((data) => {
            // console.log(data.length);
            setPois(data);
            //console.log("Commune: " + data.properties.name);
            //console.log("Code: " + data.properties.code);
            //console.log("Postal code: " + data.properties.wilaya);
          });
        })
        .catch(function (err) {
          //console.log("Fetch Error :-S", err);
        });
    },
  });

  const getDistance = useCallback((zoom) => {
    // console.log("zoom avant " + zoomVal);
    // const zoom = Math.floor(zoomVal);
    // console.log("zoom après " + zoom);

    if (zoom === 12) {
      return 7000;
    } else if (zoom === 13) {
      return 3000;
    } else if (zoom === 14) {
      return 2000;
    } else if (zoom === 15) {
      return 1100;
    } else if (zoom === 16) {
      return 700;
    } else if (zoom > 16) {
      return 300;
    } else {
      return 500000;
    }
  });

  //console.log("Draw Markers");

  return (
    <>
      <WiMarker></WiMarker>;{/* POIs */}
      <WiPoi pois={pois}></WiPoi>
    </>
  );
};

export default Markers;
