import L from "leaflet";
import React, {
  useMemo,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import WiAddress from "../../context/WiAddress";

import { Marker, useMap, Popup } from "react-leaflet";

// import { IconRestaurant } from "./IconRestaurant";
// import { IconSightseeing } from "./IconSightseeing";
// import { IconStore } from "./IconStore";

// import { IconSchool } from "./IconSchool";

import { IconWiMapper } from "./IconWiMapper";
// import WiPopup from "./WiPopup";

const WiPoi = (props) => {
  const ctx = useContext(WiAddress);

  const openPoiDialog = useCallback((val) => {
    //console.log("open Share Dialog");
    ctx.setShowPoiDialog(true);
  });

  // const getIcon = useCallback((category) => {
  //   if (category === "sightseeing") {
  //     return IconSightseeing;
  //   } else if (category === "restaurant") {
  //     return IconRestaurant;
  //   } else if (category === "school") {
  //     return IconSchool;
  //   } else if (category === "store") {
  //     return IconStore;
  //   } else {
  //     return IconWiMapper;
  //   }
  // });

  return (
    <>
      {props.pois.map((poi) => (
        <Marker
          key={poi.properties.name + poi.geometry.coordinates[1]}
          position={[poi.geometry.coordinates[1], poi.geometry.coordinates[0]]}
          icon={IconWiMapper(poi.properties.category)}
          eventHandlers={{
            click: (e) => {
              ctx.setPoiName(poi.properties.name);
              ctx.setPoiName_ar(poi.properties.name_ar);
              ctx.setPoiCategory(poi.properties.category);
              ctx.setPoiImage(poi.properties.image);
              ctx.setPoiPhoneNumber(poi.properties.phoneNumber);
              ctx.setPoiEmail(poi.properties.email);
              ctx.setPoiWebsite(poi.properties.website);
              ctx.setPoiWiAddressTown(poi.properties.wiAddress.town);
              ctx.setPoiWiAddressTown_ar(poi.properties.wiAddress.town_ar);
              ctx.setPoiWiAddressPostalCode(
                poi.properties.wiAddress.postalCode
              );
              ctx.setPoiWiAddressCode(poi.properties.wiAddress.code);
              ctx.setPoiWiAddressCountry(poi.properties.wiAddress.country);
              ctx.setPoiLongLat({
                lat: poi.geometry.coordinates[1],
                long: poi.geometry.coordinates[0],
              });

              openPoiDialog(true);
              //console.log("marker clicked", e);
            },
          }}
        >
          {" "}
        </Marker>
      ))}
    </>
  );
};

export default WiPoi;
